import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/bootsite/tippyjs.bootcss.com/website/src/components/Layout.js";
import ImageTransition from '../components/examples/ImageTransition';
import TextTransition from '../components/examples/TextTransition';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Demo = makeShortcode("Demo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Tippies use an opacity `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fade`}</code>{` transition by default.`}</p>
    <h3>{`Included animations`}</h3>
    <p>{`The package comes with extra animations for you to use:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`shift-away`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`shift-toward`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`scale`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`perspective`}</code></li>
    </ul>
    <p>{`They need to be imported separately.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token module keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`tippy.js/animations/scale.css`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Pass the animation name as the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`animation`}</code>{` prop:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`button`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  animation`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`scale`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Each of these animations also has 3 variants (normal, subtle, and extreme) using
the following format:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token module keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`tippy.js/animations/scale.css`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`tippy.js/animations/scale-subtle.css`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`tippy.js/animations/scale-extreme.css`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3>{`Custom animations`}</h3>
    <p>{`To create your own animation:`}</p>
    <ul>
      <li parentName="ul">{`Specify the animation name in the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`[data-animation]`}</code>{` attribute selector`}</li>
      <li parentName="ul">{`Target the visibility state of the tippy: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`[data-state="hidden"]`}</code>{` or
`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`[data-state="visible"]`}</code></li>
      <li parentName="ul">{`Depending on the animation, target the placement of the tippy too: e.g.
`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`[data-placement^="top"]`}</code></li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token class"
            }}>{`.tippy-tooltip`}</span><span parentName="span" {...{
              "className": "token attribute"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token attribute"
              }}>{`data-animation`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token value"
              }}>{`'rotate'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span></span><span parentName="span" {...{
              "className": "token attribute"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token attribute"
              }}>{`data-state`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token value"
              }}>{`'hidden'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span></span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rotate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`90`}</span><span parentName="code" {...{
            "className": "token unit"
          }}>{`deg`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`button`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  animation`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`rotate`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3>{`Inertia`}</h3>
    <p>{`There's a prop named `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`inertia`}</code>{` that adds an elastic inertial effect to the
tippy, which is a limited CSS-only way to mimic spring physics.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`button`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  inertia`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`You can customize this prop in your CSS:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token class"
            }}>{`.tippy-tooltip`}</span><span parentName="span" {...{
              "className": "token attribute"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token attribute"
              }}>{`data-inertia`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span></span><span parentName="span" {...{
              "className": "token attribute"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token attribute"
              }}>{`data-state`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token value"
              }}>{`'visible'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span></span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transition-timing-function`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`cubic-bezier`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3>{`Material filling effect`}</h3>
    <p>{`Import the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`animateFill`}</code>{` plugin, plus `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dist/backdrop.css`}</code>{` &
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`animations/shift-away.css`}</code>{` stylesheets.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token module keyword"
          }}>{`import`}</span>{` tippy`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`animateFill`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`tippy.js`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`tippy.js/dist/backdrop.css`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`tippy.js/animations/shift-away.css`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span>{`targets`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  animateFill`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  plugins`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`[`}</span>{`animateFill`}<span parentName="code" {...{
            "className": "token block"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3>{`CSS animations`}</h3>
    <p>{`Maybe plain transitions aren't enough for your use case. You can also use CSS
animations (e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`animate.css`}</code>{`):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`button`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`onMount`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`tooltip`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token object"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`popperChildren`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`requestAnimationFrame`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token arrow operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token object"
          }}>{`tooltip`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`classList`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`add`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`animated`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token object"
          }}>{`tooltip`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`classList`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`add`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`wobble`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`onHidden`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`tooltip`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token object"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`popperChildren`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token object"
          }}>{`tooltip`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`classList`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`remove`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`animated`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token object"
          }}>{`tooltip`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`classList`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`remove`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`wobble`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`You can also use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`@keyframes`}</code>{` and add the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`animation`}</code>{` property to your animation
selector too.`}</p>
    <h3>{`Dimensions transition`}</h3>
    <p>{`While a tippy is showing, the content inside of it may change. How do you
smoothly transition its dimensions? By default, it instantly changes size when
the content is updated. It turns out this is quite complex to do, but possible.`}</p>
    <h4>{`Partially dynamic`}</h4>
    <p>{`View the `}<a parentName="p" {...{
        "href": "https://codepen.io/atomiks/pen/LgjMbW"
      }}>{`CodePen demo`}</a>{`.`}</p>
    <h4>{`Fully dynamic`}</h4>
    <Demo mdxType="Demo">
  <span>
    <ImageTransition mdxType="ImageTransition" />
  </span>
  <TextTransition mdxType="TextTransition" />
    </Demo>
    <p>{`Code for fully dynamic transitions is still being developed and it is highly
experimental at this stage. The documentation will be updated once it's ready.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      